<template>
  <div class="cmp-sorting-options utlz-row normalize-text mb-sm-3" :class="parentClassRef">

    <div id="filters-row" class="utlz-col-md-12 filters-row">
      <div class="filter-row-wrapper">

        <div class="sort-options-wrapper flex-row-wrap justify-content-between align-items-center">
          <div class="elastic-filters">

            <!-- <div id="elastic-aggr-filters"></div> -->
            <utlz-elastic-product-filters></utlz-elastic-product-filters>

          </div>

          <div class="cmp-slot toggle-filters-slot hidden-lg">
            <slot name="toggle-mobile-filters"></slot>
          </div>

        </div>
      </div>
    </div>

    <!-- BESPOKE ZADEN-131: Moved layout icons to seperate row -->
    <div class="utlz-col-md-12 layout-options">
        <div class="sorting-options-wrapper">
          <div class="select-sorting filters-wrapper" v-if="pageSorting.length > 1">
            <span class="select-label" v-translation="{ type: 'label', code: 'label_sorting'}"></span>
            <select v-model="sorting" @change="changePageSorting()" class="small-tablet">
              <option v-for="sort in pageSorting" :key="sort.Description" :value="sort">{{sort.Description}}</option>
            </select>
          </div>

          <div class="select-pagesize">
            <span class="select-label" v-translation="{ type: 'label', code: 'label_show_item_count'}"></span>
            <select v-model="selected" @change="changePageSize()" class="small-tablet">
              <option v-for="page in pageSize" :key="page">{{page}}</option>
            </select>
          </div>

          <ul class="grid-view-btns flex-row-nowrap just-space-between">
            <li class="blocks">
              <a @click="changeLayout('blocks');" class="button square" :class="{ active: layoutType === 'blocks', 'theme-primary': layoutType === 'blocks' }">
                <i class="fa fa-th"></i>
              </a>
            </li>
            <li class="list">
              <a @click="changeLayout('rows');" class="button square" :class="{ active: layoutType === 'rows', 'theme-primary': layoutType === 'rows'}">
                <i class="fa fa-list"></i>
              </a>
            </li>
          </ul>
        </div>
    </div>
    <!-- END BESPOKE ZADEN-131 -->
  </div>
</template>

<script>
// BESPOKE ZADEN-131
import { mapGetters} from 'vuex';
import ElasticProductFilters from './../elastic/elastic-product-filters/ElasticProductFilters.vue';
// END BESPOKE ZADEN-131

export default {
  // BESPOKE
  components: {
    'utlz-elastic-product-filters': ElasticProductFilters
  },
  // END BESPOKE
  props: {
    layoutType: { type: String, required: false, default: 'blocks' },
    totalProducts: { type: Number, required: true },
    pageSize: { type: Array, required: false, default: () => [32, 64, 128] },
    pageSorting: { type: Array, required: false, default: () => [{ 'Description': 'default', 'Field': '', 'Type': 0 }] },
    pageRange: { type: Object, required: true },
    parentClassRef: { type: String, default: '', required: false }
  },
  data () {
    return {
      selected: this.pageSize[0],
      sorting: this.pageSorting[0]
    }
  },
  computed: {
    pageRangeString () {
      return this.pageRange.from + ' - ' + this.pageRange.to;
    },
    // BESPOKE ZADEN-131
    ...mapGetters('elastic', ['noProductsFound']),
    productCounter() {
      var translation = window.vue.translations.label.label_showing_result;
      var productRange = translation.replace("[1]", (this.pageRange.from + ' - ' + this.pageRange.to));
      return productRange.replace("[2]", this.totalProducts);

    }
    // END BESPOKE ZADEN-131
  },
  methods: {
    changeLayout (layoutType) {
      this.$emit('changeLayout', layoutType);
    },
    changePageSize () {
      this.$emit('changePageSize', parseInt(this.selected));
    },
    changePageSorting () {
      this.$emit('changePageSorting', this.sorting);
    }
  }
}
</script>

<style>

</style>
