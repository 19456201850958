<template>
  <div class="cmp-product-price" v-if="showPrices" :class="{ 'incl-excl': priceType, [parentClassRef]: parentClassRef  }">
    
    <!-- BESPOKE TIP: Show customerprice and standard price -->
    <ul class="price-list flex-row-nowrap align-items-baseline customer-price">
      <li class="item-label" v-translation="{ code: 'label_income_price_excl', type: 'label' }"></li>
      <li class="default">
        <span>{{ prices.price }}</span>
      </li>
      <li v-if="prices.isSalesAction" class="original salesaction">
        <span>{{ prices.basePrice }}</span>
      </li>
      <!-- <li v-if="showExclVat" class="vat-notation">
        <span v-translation="{ type: 'label', code: 'label_price1_excl_vat' }"></span>
      </li> -->
    </ul>
    <ul class="price-list flex-row-nowrap align-items-baseline standard-price">
      <li class="item-label" v-translation="{ code: 'lbl_prod_price_original_excl', type: 'label' }"></li>
      <li class="default">
        <span>{{ prices.basePrice }}</span>
      </li>
    </ul>
    <!-- END BESPOKE TIP -->
    <!-- <ul v-if="showExclVat" class="price-list flex-row-nowrap align-items-baseline price-incl">
      <li class="default">
        <span>{{ prices.priceIncl }}</span>
      </li>
      <li v-if="prices.isSalesAction" class="original salesaction">
        <span>{{ prices.basePriceIncl }}</span>
      </li>
      <li class="vat-notation"><span v-translation="{ type: 'label', code: 'label_price1_incl_vat' }"></span></li>
    </ul> -->
    <!-- END BESPOKE TIP -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    prices: { type: Object, required: false },
    parentClassRef: { type: String, default: '', required: false }
  },
  computed: {
    ...mapGetters(['showPrices', 'priceType']),
    showExclVat() {
      return this.priceType === 3;
    }
  }
}
</script>

<style>

</style>
