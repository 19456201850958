export function formatPrice (price, decimals = 2) {
  var currencyData = window.vue.globalData.webshopSettings.currencyData;
  
  var currencyCode = currencyData.currencyCode;
  var currentCulture = currencyData.currentCulture;
  var exchangeRate = currencyData.exchangeRate;

  var convertedPrice = parseFloat(price * exchangeRate);

  var options = {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2
  }

  return convertedPrice.toLocaleString(currentCulture, options);
}

export function calculatePrice (price) {
  var currencyData = window.vue.globalData.webshopSettings.currencyData;
  var exchangeRate = currencyData.exchangeRate;
  var price = parseFloat(price * exchangeRate);
  return parseFloat(price.toFixed(2));
}

// Navigates to vb page, and gives the option to cache current store
export function navigateToHybridUrl (url, { cacheState, moduleKey, stateTree }) {
  if (cacheState) {
    window.sessionStorage[moduleKey] = JSON.stringify(stateTree);
  }
  window.location.href = url;
}

export function retrieveStore (moduleKey) {
  return JSON.parse(window.sessionStorage[moduleKey]);
}
