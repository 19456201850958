<template>
  <div class="cmp-spec-dropdown utlz-reset col-md-12" v-if="availableVapoSizes !== null">
    
    <!-- BESPOKE VAPO-21: Added vapo sizes dropdown, to be used instead of checkboxes -->
    <!-- <div v-for="(filterGroup, i) in filters" :key="'filter-group-' + i" class="filters-wrapper">
      <template v-if="filterGroup.id === 'VAPO_SIZES'">
        <div class="vapo-sizes">              
          <h2 v-translation="{ type: 'label', code: 'label_select_vapo_size' }"></h2>
          <div class="custom-select">
            <i class="fa fa-angle-down" aria-hidden="true"></i>
            <select @change="selectedFilterTest($event, { id: filterGroup.id })">
              <option value="default" selected>{{ vapoSizePlaceholder }}</option>
              <option 
                v-for="(optionValue, index) in filterGroup.value" 
                :key="'option-val-' + index" 
                :value="optionValue.key"
                :selected="optionValue.active"
              >
                {{ optionValue.key }}
              </option>
            </select>
          </div>
        </div>
      </template>
    </div> -->
    <!-- END BESPOKE VAPO -->

    <!-- BESPOKE VAPO-21: Added vapo sizes dropdown, to be used instead of checkboxes -->
    <div class="filters-wrapper" v-if="availableVapoSizes">
      <template>
        <div class="tiptel-brands">              
          <h2 v-translation="{ type: 'label', code: 'label_select_tiptel_brand' }"></h2>
          <div class="custom-select">
            <i class="fa fa-angle-down" aria-hidden="true"></i>
            <select @change="selectedFilter($event)">
              <option value="default" selected>{{ vapoSizePlaceholder }}</option>
              <option 
                v-for="(size, i) in availableVapoSizes"  
                :key="'option-val-' + i" 
                :value="size.value"                
                :selected="size.value === currentVapoSizeParam"
              >
                {{ size.value }}
              </option>
            </select>
          </div>
        </div>
      </template>
    </div>
    <!-- END BESPOKE VAPO -->
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// BESPOKE VAPO
import axios from 'axios';
export default {
  data() {
    return {      
      vapoSizePlaceholder: window.vue.translations.label.label_select_default,
      availableVapoSizes: null,
      headerSet: false
    };
  },
  computed: {
    ...mapGetters(['language']),
    currentVapoSizeParam() {
      var filterParams = new URLSearchParams(window.location.search).get('filters[]');
      if (filterParams !== null) {
        return filterParams.replace("MERK","").replace("[", "").replace("]", "");
      } else {
        return "";
      }
    }
  },
  methods: {
    selectedFilter(ev) {
      var url;
      var selectedValue = ev.target.value !== 'default' ? ev.target.value : "";
      if (selectedValue.length) {        
        var encodedUri = encodeURIComponent("[" + ev.target.value + "]");
        url = `/${this.language}/productsearch/search.aspx?searchtext=&from=0&pageSize=32&filters%5B%5D=MERK${encodedUri}`;        
      } else {
        url = `/${this.language}/productsearch/search.aspx?searchtext=&from=0&pageSize=32&filters%5B%5D=`;
      }
      location.href = url;
    },
    // selectedFilterTest(ev, filter) {
    //   var selectedValue = ev.target.value !== 'default' ? ev.target.value : "";
    //   const payload = {
    //     filterActive: selectedValue.length > 0 ? true : false,
    //     filterId: filter.id,
    //     filterValue: selectedValue
    //   };
    //   this.updateFilters(payload);
    // },
  },
  mounted() {
    setTimeout(() => {
      $('h1').html = this.currentVapoSizeParam;
    }, 1000)
  },
  created() {
    axios.post('/Bespoke/TiptelBrandsWebmethods.aspx/GetTiptelBrands', {}).then(res => {
      
      var availableVapoSizes = res.data.d;
      var vapoSizesResult = [];

      Object.keys(availableVapoSizes).forEach(filterKey => {
        vapoSizesResult.push({key: filterKey, value: availableVapoSizes[filterKey]});
      });

        vapoSizesResult.sort((a,b) => {
          var keyA = a.key.toUpperCase();
          var keyb = b.key.toUpperCase();

          if (keyA < keyb) {
            return -1;
          }
          if (keyA > keyb) {
            return 1;
          }      
          return 0;
          });  
      this.availableVapoSizes = vapoSizesResult;
    });
  }
}
</script>
<style>

</style>
