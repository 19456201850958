<template>
  <div v-if="showStock" class="cmp-product-stock utlz-reset">
    <div class="stock-wrapper">
      <template v-if="stock !== null">

        <template v-if="stockType === 1">
          <div class="stock-indicators indic-1">
            <span class="stock-label" v-translation="{type: 'label', code: 'label_stock_indicator'}">:</span>
            <span class="stock-count">{{ stockTotal }}</span>
          </div>
        </template>
        
        <template v-else-if="stockType === 2">
          <div class="stock-indicators indic-2">
            <span class="stock-label" v-translation="{type: 'label', code: 'label_stock_indicator'}">:</span>
            <img :src="'/' + stockIndicator.image" />
          </div>
        </template>
        
        <template v-else-if="stockType === 3">
          <div class="stock-indicators indic-3">
            <span class="stock-label" v-translation="{type: 'label', code: 'label_stock_indicator'}">:</span>
            <span>{{ stockIndicator.description }}</span>
          </div>
        </template>
        
        <template v-else-if="stockType === 4">
          <div class="stock-indicators indic-4">
            <img :src="'/' + stockIndicator.image" />
            <span>{{ stockIndicator.description }}</span>
          </div>
        </template>

      </template>
      <div v-else-if="showStock" class="stock-status-wrapper text-placeholder pulse">
        <span class="loader"></span>
        <span v-translation="{ type: 'label', code: 'label_retrieving_stock' }"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    stock: { type: Object, required: false }
  },
  computed: {
    ...mapGetters(['showStock', 'stockIndicators', 'stockType']),
    stockTotal () {
      return this.stock.stockTotal < 0 ? 0 : this.stock.stockTotal;
    },
    // BESPOKE TIP
    // Hotfix to show the correct stock indicator
    stockIndicator () {
      let indicatorIndex = 0;
      this.stockIndicators.forEach((indicator, index) => {
        if (indicator.quantity <= this.stock.stockTotal) {
          indicatorIndex = index; 
        }
      });
      return this.stockIndicators[indicatorIndex];
      return this.stockIndicators.find(indicator => this.stock.stockTotal <= indicator.quantity);
    }
    // END BESPOKE TIP
  }
}
</script>

<style>
</style>
