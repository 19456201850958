import axios from 'axios';

const state = {
};

const getters = {
  shoppingCart (state) {
    return state.shoppingCartRows;
  },
  totalPrice (state) {
    return state.totalPrice;
  },
  totalQuantity (state) {
    return state.totalQuantity;
  }
};

const actions = {
  addToCart ({ rootGetters }, payload) {
    let endpoint = rootGetters.orderProductEndpoint;
    let userLoggedOn = rootGetters.userLoggedOn;
    const products = Array.isArray(payload) ? payload : [payload];
    return new Promise((resolve, reject) => {
      axios.post(endpoint, JSON.stringify({ products: products, loggedInStatus: userLoggedOn })).then(
        res => {
          const response = res.data.d;
          if (response.errorMessages) {
            if (response.errorMessages === 'log in status changed') {
              window.location.replace('/webshop/login.aspx?RedirectUrl=' + window.location.pathname + window.location.search);
            } else {
              alert(response.errorMessages);
            }
          } else {
            resolve(true);

            // REFERS TO FUNCTION CALL IN CUSTOM.JS AS LONG AS SHOPPINGCART CONTROL IS NOT VUEJS
            window.updateShoppingCart(response.product_count, response.orderTotal, response.shoppingcartHtml);

            if (response.errorMessages) {
              window.updateErrorMessage(response.errorMessages);
            }

            var message = response.order_message;
            if (response.totalProductsAdded > 0) {
              window.updateOrderMessage(message);
              // BESPOKE TIP
              // We need to manually update the product counter
              // in the shoppingcart control counter
              $('.badge-total').text(response.product_count);
              // END BESPOKE TIP
            }
            window.set_shopping_cart_delete_buttons();
            // END CUSTOM.JS FUNCTION CALLS
          }
        })
        .catch(err => {
          // @TODO Error handling
          reject(err);
        });
    });
  }
};

const mutations = {
  addToCart (state, productRow) {
    state.shoppingCartRows.push(productRow);
  }
};

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};
